<template>
  <b-modal
    id="breeze-modal"
    v-model="show"
    @hide="$emit('input', false)"
  >
    <template
      v-if="title !== ''"
      #modal-title
    >
      {{ title }}
    </template>
    <slot />

    <template #modal-footer>
      <b-button
        class="btn btn-warning"
        @click="$emit('ok')"
      >
        {{ okButton }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'BreezeModal',
  props: {
    value: Boolean,
    title: {
      type: String,
      default: '',
    },
    okButton: {
      type: String,
      default: 'Ok',
    },
  },
  data() {
    return {
      show: false,
    }
  },
  watch: {
    value() {
      this.show = this.value
    },
  },
  created() {
    this.show = this.value
  },
}
</script>

<style scoped>

</style>
