<template>
  <b-card title="My reviews">
    <p>After the date users can leave a review by giving their date location a rating and selecting specific tags.
      Please note that users are *more critical* because we choose the location for them.
      Giving feedback is not mandatory and is not shared publicly.  </p>

    <div class="d-flex">
      <div class="p-relative mt-1 mr-1">
        <label
          for="from"
          class="on-border"
        >From</label>
        <b-form-datepicker
          v-model="from"
          :value-as-date="true"
          @input="loadReviews"
        />
      </div>
      <div class="p-relative mt-1 mr-1">
        <label
          for="until"
          class="on-border"
        >Until</label>
        <b-form-datepicker
          v-model="until"
          :value-as-date="true"
          @input="loadReviews"
        />
      </div>
      <div class="p-relative mt-1 mr-1">
        <label
          for="sortOrder"
          class="on-border"
        >Sorting</label>
        <b-form-select
          v-model="sortOrder"
          style="height: 43px;"
          @input="loadReviews"
        >
          <b-form-select-option value="latest">
            Latest
          </b-form-select-option>
          <b-form-select-option value="critical">
            Most critical
          </b-form-select-option>
          <b-form-select-option value="favourable">
            Most favourable
          </b-form-select-option>
        </b-form-select>
      </div>
      <div class="p-relative mt-1 mr-1">
        <label
          for="filteredTags"
          class="on-border"
        >Filter on tags</label>
        <multiselect
          v-model="filteredTags"
          :options="possibleTags"
          :multiple="true"
          :show-labels="false"
          :close-on-select="false"
          :clear-on-select="false"
          label="label"
          track-by="value"
          placeholder="Pick some"
          @input="loadReviews"
        >
          <template
            v-slot:selection="{ values, search, isOpen }"
          ><span
            v-if="values.length"
            v-show="!isOpen"
            class="multiselect__single"
          >{{ values.length }} options selected</span>
          </template>
        </multiselect>
      </div>
      <div class="p-relative mt-1 mr-1">
        <label
          for="withReview"
          class="on-border"
          style="width: 200px;"
        >Only reviews with written text</label>
        <b-form-checkbox
          v-model="withReview"
          :checked="withReview"
          class="mt-1 ml-2"
          switch
          inline
          @input="loadReviews"
        />
      </div>
    </div>
    <div>
      <p
        v-if="reviews.length > 0"
        class="pt-2 mb-0"
      >
        Showing first {{ Math.min(100, reviews.length) }} of {{ total }} reviews between
        {{ formatDate('yyyy-MM-dd', from) }} and {{ formatDate('yyyy-MM-dd', until) }}:
      </p>
      <p
        v-else
        class="pt-2 mb-0"
      >
        No reviews found between {{ formatDate('yyyy-MM-dd', from) }} and {{ formatDate('yyyy-MM-dd', until) }}
      </p>
      <Review
        v-for="review in reviews"
        :key="review.feedbackId"
        :review="review"
        @reply="startReply"
        @exception="startException"
      />
    </div>
    <BreezeModal
      ref="replyModal"
      v-model="reply.open"
      title="Reply to review"
      ok-button="Send"
      @ok="sendReply"
    >
      <p>
        Type your reply here and we’ll send that over to the user in the app.
      </p>
      <b-form-textarea
        v-model="reply.message"
        placeholder="Type your reply here"
        rows="3"
        max-rows="6"
      />
    </BreezeModal>
    <BreezeModal
      ref="exceptionModal"
      v-model="exception.open"
      title="Let us know if you don’t agree with a review"
      :ok-button="exception.review.exceptionDisabled ? 'Close' : 'Send'"
      @ok="() => exception.review.exceptionDisabled ? exception.open = false : sendException()"
    >
      <p>
        It could be that a negative review is not your fault, but, for example, ours. If so, you can let us know why you
        think this is the case. We’ll take a look and remove it.
      </p>
      <p v-if="exception.review.exceptionDisabled">
        You can’t remove a review anymore after 1 month or if you’ve responded to it.
      </p>
      <b-form-textarea
        v-else
        v-model="exception.message"
        placeholder="Reason"
        rows="3"
        max-rows="6"
      />
    </BreezeModal>
  </b-card>
</template>

<script>

import { mapGetters } from 'vuex'
import { Deal } from '@/_models'
import Review from '@/components/Review.vue'
import { requests } from '@/_helpers'
import BreezeModal from '@/components/BreezeModal.vue'
import { formatDate } from '@/_helpers/util'
import { event } from 'vue-gtag'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    BreezeModal,
    Multiselect,
    Review,
  },
  data() {
    return {
      reviews: [],
      total: 0,
      loading: false,
      from: new Date(),
      until: new Date(),
      sortOrder: 'latest',
      reply: {
        open: false,
        message: '',
        review: null,
      },
      exception: {
        open: false,
        message: '',
        review: {},
      },
      possibleTags: [],
      filteredTags: [],
      withReview: true,
    }
  },
  computed: {
    ...mapGetters({
      location: 'authentication/location',
    }),
  },
  created() {
    this.from.setMonth(this.from.getMonth() - 1)
    this.loadReviews()
  },
  methods: {
    dealList(deal) {
      return [new Deal(deal.id)]
    },
    getVisibleDeals(deals) {
      return deals.filter(deal => deal.deprecatedAt == null && deal.dealType.active)
    },
    loadReviews() {
      if (this.from > this.until || this.location == null) return
      const tags = this.filteredTags.map(tag => tag.value)
      this.location.getReviews(this.from, this.until, this.sortOrder, tags, this.withReview).then(reviews => {
        this.reviews = reviews.data.list ?? []
        this.total = reviews.data.total ?? 0
        this.possibleTags = (reviews.data.tags ?? []).map(tag => ({
          value: tag.id,
          label: `${tag.name} (${tag.useCount})`,
        }))
      })
    },
    startReply(review) {
      this.reply.review = review
      this.reply.open = true
      this.reply.message = null
    },
    startException(review) {
      this.exception.review = review
      this.exception.open = true
      this.exception.message = null
    },
    sendReply() {
      if ((this.reply.message?.length ?? 0) < 20) {
        this.$bvToast.toast('Please enter a message of at least 20 characters', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
        return
      }
      requests.put(`feedbacks/${this.reply.review.feedbackId}/reply`, { message: this.reply.message })
      this.$bvToast.toast('Reply sent', {
        title: 'Sent',
        variant: 'success',
        solid: true,
      })
      this.reply.open = false
      for (let i = 0; i < this.reviews.length; i += 1) {
        if (this.reviews[i].feedbackId === this.reply.review.feedbackId) {
          this.$set(this.reviews[i], 'replyMessage', this.reply.message)
          this.$set(this.reviews[i], 'replyAt', new Date())
          break
        }
      }
      event('send-reply')
    },
    sendException() {
      if ((this.exception.message?.length ?? 0) < 10) {
        this.$bvToast.toast('Please enter a message of at least 10 characters', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
        return
      }
      requests.put(`feedbacks/${this.exception.review.feedbackId}/exception`, { message: this.exception.message })
      this.$bvToast.toast('We received your message', {
        title: 'Sent',
        variant: 'success',
        solid: true,
      })
      this.exception.open = false
      this.reviews = this.reviews.filter(review => this.exception.review.feedbackId !== review.feedbackId)
      event('send-exception')
    },
    formatDate(format, date) {
      return formatDate(format, date)
    },
  },
}
</script>

<style lang="scss" scoped>
.on-border {
  position: absolute;
  top: -8px;
  left: 5px;
  padding: 0 0.5rem;
  background: white;
  z-index: 1;
}

.p-relative {
  position: relative;
}

::v-deep {
  .multiselect__tags {
    min-height: 42px;
  }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
