<template>
  <b-card
    class="review"
  >
    <div class="d-flex header justify-content-between p-0 pb-1">
      <div class="d-flex align-items-center">
        <span class="rating">
          <feather-icon
            v-for="i in 5"
            :key="i"
            icon="StarIcon"
            size="25"
            :class="['star', (i <= review.rating) ? 'filled' : '']"
          />
        </span>
        <span class="date">
          {{ formatDate('dd-MM-yyyy at hh:mm', new Date(review.dateAt)) }}
        </span>
      </div>
      <div>
        <b-button
          :class="{'btn btn-warning': true, 'disabled': review.exceptionDisabled}"
          size="sm"
          @click="$emit('exception', review)"
        >
          Not applicable to us
        </b-button>
      </div>
    </div>
    <div class="d-flex">
      <span
        v-for="(tag) in review.negativeTags"
        :key="tag"
        class="tag negative"
      >
        {{ tag }}
      </span>
      <span
        v-for="(tag) in review.positiveTags"
        :key="tag"
        class="tag positive"
      >
        {{ tag }}
      </span>
    </div>
    <p class="explanation">
      {{ review.explanation?review.explanation:'No message' }}
    </p>
    <div
      v-if="review.replyMessage"
      class="reply"
      style="white-space: pre-line"
    >
      <div class="reply-header">
        Your reply
        <span class="date">
          {{ formatDate('dd-MM-yyyy at hh:mm', new Date(review.replyAt)) }}
        </span>
      </div>
      {{ review.replyMessage }}
    </div>
    <div
      v-else
      class="d-flex justify-content-end"
    >
      <b-button
        :class="{'btn btn-info': true}"
        size="sm"
        @click="$emit('reply', review)"
      >
        <feather-icon
          icon="MessageSquareIcon"
          class="reply-button"
        />
        Reply to user
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { formatDate } from '@/_helpers/util'

export default {
  name: 'Review',
  components: {
  },
  props: {
    review: {
      type: Object,
      default: () => {
      },
    },
  },
  watch: {
    review: {
      deep: true,
      handler() {
        this.setExceptionDisabled()
      },
    },
  },
  created() {
    this.setExceptionDisabled()
  },
  methods: {
    formatDate(format, date) {
      return formatDate(format, date)
    },
    setExceptionDisabled() {
      const monthAgo = (new Date())
      monthAgo.setMonth(monthAgo.getMonth() - 1)
      this.review.exceptionDisabled = this.review.replyMessage != null || new Date(this.review.createdAt) < monthAgo
    },
  },
}
</script>

<style scoped lang="scss">
  .review {
    color: #2140A1;
    border: 1px solid rgba(0, 0, 0, 0.5);
    margin-top: 20px;

    ::v-deep .card-body {
      padding: 1rem;
    }
  }

  .header {
    padding: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  .rating {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    padding-right: 5px;
  }

  .star {
    color: #FF7154;

    &.filled {
      fill: #FF7154;
    }
  }

  .date {
    padding-left: 5px;
    font-family: 'DM Sans',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.015em;
  }

  .tag {
    border: 1px solid #6AA889;
    border-radius: 14px;
    padding: 5px 15px;
    min-width: 100px;
    text-align: center;
    color: #6AA889;
    margin-right: 10px;
    margin-top: 10px;

    &.negative {
      color: #FF7153;
      border-color: #FF7153;
    }
  }

  .explanation {
    margin-top: 10px;
    font-family: 'DM Sans',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.015em;
  }

  .reply {
    border-left: 4px solid rgba(0, 0, 0, 0.5);
    padding: 5px 10px;

    .reply-header {
      font-weight: bold;
      margin-bottom: 8px;
    }

    .date {
      font-weight: normal;
      border-left: 1px solid rgba(0, 0, 0, 0.2);
      padding-left: 5px;
    }
  }

  .reply-button {
    fill: white;
    transform: rotateY(180deg);
  }
</style>
